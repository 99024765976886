import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";



// views without layouts

import Index from "views/Index.js";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import ReactGA from 'react-ga';
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCBT_hbcoHqTo-3NeK9pnax0EtQlftur_Y",

  authDomain: "rockymountainbuildingproducts.firebaseapp.com",

  projectId: "rockymountainbuildingproducts",

  storageBucket: "rockymountainbuildingproducts.appspot.com",

  messagingSenderId: "774255075108",

  appId: "1:774255075108:web:8986718102dc52da815db1",

  measurementId: "G-GFM174WD4Y"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
ReactGA.initialize('G-VDBYX7BGL8');
ReactDOM.render(
  <BrowserRouter>

    <Switch>            
      
      
      
      <Route path="/" exact component={Index} />
      
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

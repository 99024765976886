/*eslint-disable*/
import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import { useCookies } from "react-cookie";

export default function Navbar({fixed}) {
  const history = useHistory();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['age-verified']);


  
const routeChange = (pathName) =>{ 
  console.log("routeChanged called ");
 
    //getAnalytics(getApp()).logEvent('user_engagement', "homepage_click",pathName);
    
    if(pathName=="products")
    {        
      let membershipURL = "https://ovou.me/puwuvesapalijalo"
      window.open(membershipURL, '_blank', 'noopener,noreferrer');
    }
    else if(pathName=="DIS")
    {
      
      let careersURL = "https://deadinv.com/"
      window.open(careersURL, '_blank', 'noopener,noreferrer');
    }    
  
}
  return (
    <>  
    
     
      <nav className="top-0  z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
     
        
        <div className="container px-4 md:px-0 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <button
                className=""                
              >
          <img
                alt="..."
                className="inline-block navLogo"
                src={require("assets/img/logo.png").default}
              />
               <img
                alt="..."
                className="inline-block navLogo p-2"
                src={require("assets/img/DIS.png").default}
              />
            </button>
            <button
              className="w-1/4 cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"          
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars text-black"></i>
            </button>
          </div>
          
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            
            <ul className="flex flex-col lg:flex-row  lg:justify-start list-none ">
              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("products")}}                                   
                >
                  <h1 className="text-black navText">Building Products</h1>
                  {/* <span className=" text-black navText lg:hidden inline-block ml-2">HOME</span> */}
                </button>
              </li>

             
            

              <li className="flex items-center">
              <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("DIS")}}                                   
                >
                  <h1 className="text-black navText">Dead Inventory Store</h1>
                  {/* <span className=" text-black navText lg:hidden inline-block ml-2">HOME</span> */}
                </button>
              </li>
            </ul>
            <div className="flex pt-2 md:pt-0 items-center lg:ml-auto">
              <div className="flex flex-col">
                <div className="flex">Rob Peters</div>
                <div className="flex">
                  <a href = "tel:250-884-4103">
                    250-884-4103
                  </a>
                </div>
                <div className="flex">
                  <a href = "mailto:rpeters@buildingproducts.ca">
                  rpeters@buildingproducts.ca
                  </a>
                  
                </div>
              </div>      
          </div>
          </div>
        
          
        
        </div>

      </nav>
     
      
      
      
    </>
  );
}

import React, {  useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Footer() {
 
  const history = useHistory();


  const analytics = getAnalytics();




  
  const routeChange = (pathName) =>{ 
    console.log("routeChanged called ");
   
      //getAnalytics(getApp()).logEvent('user_engagement', "homepage_click",pathName);
      // if(pathName =="home")
      // {
      //   window.scrollTo(0, 0)

      // }

      if(pathName=="products")
      {        
        let membershipURL = "https://ovou.me/puwuvesapalijalo"
        window.open(membershipURL, '_blank', 'noopener,noreferrer');
      }
      else if(pathName=="DIS")
      {
        
        let careersURL = "https://deadinv.com/"
        window.open(careersURL, '_blank', 'noopener,noreferrer');
      }    
    
  }

  return (
    <>
      
      <div className="bottom-auto relative bg-white">        
        <div className="mx-auto w-full">
          <hr className="border-blueGray-300" />

          <div className="container mx-auto px-2 flex  justify-start md:justify-between w-full flex-col md:flex-row text-left md:text-center md:items-center"> 
            
            <div className="flex flex-row">
              <img
                alt="..."
                className="inline-block navLogo"
                src={require("assets/img/logo.png").default}
                onClick = {()=>{routeChange("products")}}
              />     
              <img
                alt="..."
                className="inline-block navLogo p-2"
                src={require("assets/img/DIS.png").default}
                onClick = {()=>{routeChange("DIS")}}
              />                         
            </div>
            {/* links */}
            
            <div className="w-full flex">                                    
              <div className="flex w-full flex-col md:flex-row text-left md:text-center items-center md:pt-0">                                               
                <div className="flex flex-col md:flex-row">
                  <div className="pt-2 md:pt-0 md:pl-0 text-white font-normal  lg:pl-0">
                    <div className="justify-center flex">
                      
                      <button className="" 
                        onClick={()=>{routeChange("products")}}                                                         
                      >
                      <h1 className="mobileFooterTextSize">Building Products </h1>
                      </button>
                    </div>            
                  </div>
                  
                  <div className="pt-2 md:pt-0 text-white font-normal md:pl-0 lg:pl-0 flex justify-start">
                    <div
                        >
                      <button
                      onClick={()=>{routeChange("DIS")}}                                   
                        
                      >
                        <h1 className="mobileFooterTextSize no-wrap"> Dead Inventory Store</h1>
                      </button>
                      </div>            
                  </div>
                </div>
                
              </div>                        
            </div>
            
          
            <div className="flex pt-2 md:pt-0 items-center">
              <div className="flex flex-col">
                <div className="flex">Rob Peters</div>
                <div className="flex">
                  <a href = "tel:250-884-4103">
                    250-884-4103
                  </a>
                </div>
                <div className="flex">
                  <a href = "mailto:rpeters@buildingproducts.ca">
                  rpeters@buildingproducts.ca
                  </a>
                  
                </div>
              </div>      
            </div>

          </div>        
        </div>
      </div>
    
    </>
    
  );
}

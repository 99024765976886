/*eslint-disable*/
import React,{  useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import { useHistory } from "react-router-dom";
import StockBanner from "components/StockBanner/StockBanner";
import ReactGA from 'react-ga';
import { getAnalytics, logEvent } from "firebase/analytics";



export default function Index() {




  const analytics = getAnalytics();

  const [showModal, setShowModal] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  let history = useHistory();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView() 
  // handle scroll down
  const handleClick = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
    
  };

 
  const routeChange = (pathName) =>{ 
    console.log("routeChanged called ");
   
      //getAnalytics(getApp()).logEvent('user_engagement', "homepage_click",pathName);
    
      if(pathName=="products")
      {        
        let membershipURL = "https://ovou.me/puwuvesapalijalo"
        window.open(membershipURL, '_blank', 'noopener,noreferrer');
      }
      else if(pathName=="DIS")
      {
        
        let careersURL = "https://deadinv.com/"
        window.open(careersURL, '_blank', 'noopener,noreferrer');
      }    
    
  }
  

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);

  }, [])

  return (
    <>
   
      <IndexNavbar fixed />
      <StockBanner></StockBanner>

      
      <section className="py-6 header welcomeBackgroundColor h-screen mobileStyleBG" style={{backgroundImage: `url(${require("assets/img/bannerBG.jpg").default})`}}>                       
        <div className="container justify-center mx-auto relative items-center flex flex-row ">
      
          
          <div className="text-center  flex flex-wrap sm:w-10/12 md:w-6/12 lg:w-6/12 xl:w-6/12 p-5">
            <div className="sm:w-full md:w-full">
              <div className = "sm:w-full md:w-full">
                <img
                    alt="..."
                    className="mx-auto indexLogo"
                    src={require("assets/img/logo.png").default}
                  />
              </div>
              <div className=" sm:pt-0 md:mt-3 lg:pt-3">
                <h2 className="animate-charcter" style={{textShadow: "-2px 4px 4px #0c1c38"}}>
                  Rob Peters
                </h2>
                <p className="mt-4 text-lg font-normal leading-relaxed text-white" style={{textShadow: "-2px 4px 4px #0c1c38"}}>
                 Rocky Mountain Building Products Inc.
                </p>
                <div className="mt-6 md:mt-12 lg:mt-12 xl:mt-12 w-1/2" style={{width:"250px",marginRight:"auto",marginLeft:"auto"}}  >
                  
                  <button                    
                    onClick={()=>{routeChange("products")}}
                    className=" w-full get-started text-white font-bold  py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    View Products
                  </button>                
                  
                </div>
              </div>
              <div className="mt-24 md:mt-40 lg:mt-16 xl:mt-di">        
                <span className="">
                  <img
                      className="mx-auto "
                      style={{height:"100%",width:"100%",maxWidth:"40px"}}
                      src={require("assets/img/downIcon.png").default}
                      alt="..."
                      onClick={executeScroll}
                    /> 
                </span>
              </div>
            </div>
          </div>
        </div>
          
      </section>
      
      
      <section ref={myRef} className="header h-screen px-12 relative items-center flex flex-wrap " style={{ minHeight:"400px", backgroundColor:"#0c1c38",backgroundSize:"cover"}}>

        <div className="container mx-auto " >
          <div className="flex flex-col items-center justify-center"  >
            
            <div className="text-center">
             <div className = "sm:w-full md:w-full pb-4">
                <img
                    alt="..."
                    className="mx-auto indexLogo"
                    src={require("assets/img/DIS.png").default}
                  />
              </div>

                <h3 className="text-3xl font-semibold text-white">
                The Dead Inventory Store
                </h3>
                <p className="mt-4 text-lg leading-relaxed text-white">
                  The Dead Inventory Store is a B2B web based service that is focused on moving your dead inventory.<br />
                  Free up space and cash! Stay tuned.                 
                </p>                
              
            </div>
            
            <div className="w-full">
                <div className = "flex-auto mx-auto items-center text-center pt-3" >
                <button
                  onClick={()=>{routeChange("DIS")}}
                  style={{width:"250px"}}
                  className="ocmOutlineInv w-full get-started text-white font-bold py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue2-500 active:bg-lightBlue2-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"                  
                >
                  View Store
                </button>                
                </div>
                
              </div>
          </div>
        </div>

      </section>

      <Footer />
      
    </>
  );
}
